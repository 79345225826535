@import '@angular/cdk/overlay-prebuilt.css';

// Theme Components & overrides / extentions
@import '../../big-direkt-de/src/scss/components/cookiebot';

html {
    min-height: 100%;
}

.overflow-scroll-y {
    overflow: hidden scroll;
}
